import { Line, mixins } from "vue-chartjs";

// This is needed to make the time scale work
import "chartjs-adapter-date-fns";

export default {
  name: "line-chart",
  extends: Line,
  mixins: [mixins.reactiveProp],
  props: {
    options: { type: Object, required: true }
  },
  data() {
    return {
      ctx: null
    };
  },
  watch: {
    options() {
      this.renderChart(this.chartData, this.options);
    }
  },
  mounted() {
    this.$watch(
      "chartData",
      (newVal, oldVal) => {
        if (!oldVal) {
          this.renderChart(this.chartData, this.options);
        }
      },
      { immediate: true }
    );
  }
};
