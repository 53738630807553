import dates from "@/common/dates.js";
import formatting from "@/common/formatting";

export const chartColors = {
  default: {
    primary: "#209CEE",
    info: "#362963",
    danger: "#FF3860",
    complianceFull: "green",
    compliancePartial: "orange",
    complianceNone: "red",
    other1: "brown"
  }
};

export const AllLocations = "Overall";

export const baseChartOptions = {
  maintainAspectRatio: false,
  legend: {
    display: false
  },
  responsive: true,
  layout: {
    padding: {
      left: 0,
      right: 0,
      top: 0,
      bottom: 0
    }
  }
  // onClick: function(evt) {
  //   // Used here, this refers to the chart and this.getElementAtEvent(evt) will give us which dataset was clicked
  //   // but we cannot reference this.$emit to pass the click back up
  //   // Currently this is handle within the component, see KpiReports
  //   const chartIndex = this.getElementAtEvent(evt);
  //   if (chartIndex.length !== 0) {
  //     const datasetIndex = chartIndex[0]._datasetIndex;
  //     const position = chartIndex[0]._index;
  //     const info = {
  //       datasetIndex: datasetIndex,
  //       valueIndex: position,
  //       label: this.tooltip._data.labels[position],
  //       value: this.tooltip._data.datasets[datasetIndex].data[position]
  //     };
  //     console.log(this, info);
  //     // Vue.$emit("click", info);
  //   } else {
  //     console.log("You selected the background!");
  //   }
  // }
};

export const chartOptionsMain = {
  ...baseChartOptions,
  legend: {
    display: true,
    position: "bottom"
  },
  tooltips: {
    callbacks: {
      label: tooltipItem => {
        return tooltipItem.value > 0 ? formatting.round(tooltipItem.value) : tooltipItem.value;
      }
    }
  }
};

export function chartOptionsUnits(label, unit, lookup, tooltipCallback) {
  return {
    ...chartOptionsMain,
    scales: {
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: label
          },
          ticks: {
            // stepSize: 1,
            callback: function (value, index, values) {
              return lookup ? lookup[value] : value + (unit ? " " + unit : "");
            },
            min: 0
          }
        }
      ],
      xAxes: [
        {
          id: "xAxisTime",
          type: "category",
          ticks: {
            callback: function (label) {
              return dates.shortFormatTime(label);
            }
          }
        },
        {
          id: "xAxisDate",
          type: "category",
          gridLines: {
            drawOnChartArea: false // only want the grid lines for one axis to show up
          },
          ticks: {
            callback: function (value, index, values) {
              // display date just once in group, we find index below and above this one that has different date
              var thisDate = dates.shortFormatDate(value);
              var startIndex = 0;
              for (var i = index; i >= 0; i--) {
                if (dates.shortFormatDate(values[i]) !== thisDate) {
                  startIndex = i + 1;
                  break;
                }
              }
              // var endIndex = values.length - 1;
              // for (var j = index; j <= endIndex; j++) {
              //   if (dates.shortFormatDate(values[j]) !== thisDate) {
              //     endIndex = j - 1;
              //     break;
              //   }
              // }
              // to display date in the middle, we would need to be able to custom format the ticks for the start and end of the group
              // return Math.floor((endIndex - startIndex) / 2) + startIndex === index ? thisDate : "";

              // display date at first index in group only
              return startIndex === index ? thisDate : "";
            }
          }
        }
      ]
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem, data) => {
          // use custom function if available, passing back data, else, use the lookup array, else, use standard value with units if available
          // prettier-ignore
          return tooltipCallback
            ? tooltipCallback(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index])
            : lookup
              ? lookup[tooltipItem.value]
              : tooltipItem.value + (unit ? " " + unit : "");
        },
        title: tooltipItem => {
          return dates.formatDateTime(tooltipItem[0].xLabel);
        }
      }
    }
  };
}

export function chartOptionsUnitsTimeScale(label, unit, lookup, tooltipCallback, fromDate, toDate) {
  return {
    // deep copy the main options, otherwise original values get overwritten
    ...JSON.parse(JSON.stringify(chartOptionsMain)),
    title: {
      display: false
    },
    scales: {
      xAxes: [
        {
          type: "time",
          time: {
            // unit: "day", // Defaults to best match, but note displays time values if date rang less than around 9 days
            tooltipFormat: "dd MMM yy HH:mm" // moment.js format
          },
          ticks: {
            min: fromDate?.getTime(),
            max: toDate?.getTime()
          }
        }
      ],
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: label
          },
          ticks: {
            callback: function (value, index, values) {
              return lookup ? lookup[value] : value + (unit ? " " + unit : "");
            },
            min: 0
          }
        }
      ]
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem, data) => {
          // use custom function if available, passing back data, else, use the lookup array, else, use standard value with units if available
          // prettier-ignore
          return tooltipCallback
            ? tooltipCallback(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index])
            : lookup
              ? lookup[tooltipItem.value]
              : tooltipItem.value + (unit ? " " + unit : "");
        }
      }
    }
  };
}

export function chartOptionsSmall() {
  return {
    ...baseChartOptions,
    layout: {
      padding: 10
    },
    legend: {
      display: false,
      position: "bottom"
    },
    title: {
      display: false
    },
    scales: {
      xAxes: [
        {
          type: "time",
          time: {
            tooltipFormat: "dd MMM yy HH:mm" // moment.js format
          },
          ticks: {
            display: false
          },
          gridLines: {
            display: false
          }
        }
      ],
      yAxes: [
        {
          ticks: {
            display: false
          },
          gridLines: {
            display: false
          }
        }
      ]
    }
    // tooltips: {
    //   callbacks: {
    //     label: (tooltipItem, data) => {
    //       // use custom function if available, passing back data, else, use the lookup array, else, use standard value with units if available
    //       // prettier-ignore
    //       return tooltipCallback
    //         ? tooltipCallback(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index])
    //         : lookup
    //           ? lookup[tooltipItem.value]
    //           : tooltipItem.value + (unit ? " " + unit : "");
    //     }
    //   }
    // }
  };
}

export const chartOptionsYZero = {
  ...chartOptionsMain,

  // tooltips: {
  //   backgroundColor: "#f5f5f5",
  //   titleFontColor: "#333",
  //   bodyFontColor: "#666",
  //   bodySpacing: 4,
  //   xPadding: 12,
  //   mode: "nearest",
  //   intersect: 0,
  //   position: "nearest"
  // },
  // scales: {
  //   yAxes: [
  //     {
  //       barPercentage: 1.6,
  //       gridLines: {
  //         drawBorder: false,
  //         color: "rgba(29,140,248,0.0)",
  //         zeroLineColor: "transparent"
  //       },
  //       ticks: {
  //         padding: 20,
  //         fontColor: "#9a9a9a"
  //       }
  //     }
  //   ],

  scales: {
    yAxes: [
      {
        ticks: {
          min: 0
        }
      }
    ]
  }

  //   xAxes: [
  //     {
  //       barPercentage: 1.6,
  //       gridLines: {
  //         drawBorder: false,
  //         color: "rgba(225,78,202,0.1)",
  //         zeroLineColor: "transparent"
  //       },
  //       ticks: {
  //         padding: 20,
  //         fontColor: "#9a9a9a"
  //       }
  //     }
  //   ]
  // }
};

export const percentageChartOptions = {
  ...chartOptionsMain,

  tooltips: {
    callbacks: {
      label: tooltipItem => {
        return tooltipItem.value + "%";
      }
    }
  },
  scales: {
    yAxes: [
      {
        ticks: {
          // Include a % sign in the ticks
          callback: function (value, index, values) {
            return value + "%";
          }
        }
        // gridLines: {
        //   display: false
        // }
      }
    ],
    xAxes: [
      {
        // ticks: {
        //   minRotation: 60
        // }
      }
    ]
  }
};

export const defaultDataSetOptions = {
  fill: false,
  borderColor: chartColors.default.primary,
  backgroundColor: chartColors.default.primary,
  borderWidth: 2,
  borderDash: [],
  borderDashOffset: 0.0,
  pointBackgroundColor: chartColors.default.primary,
  pointBorderColor: "rgba(255,255,255,0)",
  pointHoverBackgroundColor: chartColors.default.primary,
  pointBorderWidth: 20,
  pointHoverRadius: 4,
  pointHoverBorderWidth: 15,
  pointRadius: 4
};

export const filltDataSetOptions = {
  fill: true,
  borderColor: chartColors.default.primary,
  backgroundColor: chartColors.default.primary,
  borderWidth: 2,
  borderDash: [],
  borderDashOffset: 0.0,
  pointBackgroundColor: chartColors.default.primary,
  pointBorderColor: "rgba(255,255,255,0)",
  pointHoverBackgroundColor: chartColors.default.primary,
  pointBorderWidth: 20,
  pointHoverRadius: 4,
  pointHoverBorderWidth: 15,
  pointRadius: 4
};
